
import { Component, Vue } from "vue-property-decorator";
import { Foundation } from "@/utils/Foundation";
import API_yiyePage from "@/utils/API/yiye/yiyepage";
import { _yiyepage } from "@/utils/pageConfig/yiye/yiyepage";
import API_MerchantList from "@/utils/API/merchant/MerchantList";
import moment from "moment";

@Component({
    components: {},
})
export default class UserList extends Vue {
    /*表格相关*/
    pageConfig: any = _yiyepage;
    tableData: any = [];
    total: number = 0;
    params: any = {
        current: 1,
        size: 20,
    };
    totalClickNum: any = "";
    validClickNum: any = "";

    async mounted() {
        // this.checkTime();
        await this.getAllMerchant();
        await this.getTableData();
        await this.getclickNum();
    }

    async getAllMerchant() {
        let options: any = [];
        let res: any = await API_MerchantList.getAllMerchant();
        res.map((e: any) => {
            options.push({ label: e.coName, value: e.id });
        });
        this.pageConfig.search[0].options = options;
    }

    async getclickNum() {
        console.log(this.params);
        let res: any = await API_yiyePage.clickNum(this.params);
        console.log(res);
        this.totalClickNum = res.totalClickNum;
        this.validClickNum = res.validClickNum;
    }

    checkTime() {
        const end = new Date();
        const start = new Date(end);
        // 开始时间
        start.setMonth(start.getMonth() - 1);
        start.setHours(0, 0, 0, 0);
        // 结束时间
        end.setHours(23, 59, 59, 999);
        console.log(start, end);
        this.params["beginTime"] = Foundation.dateFormat(start, "YYYYMMDD");
        this.params["endTime"] = Foundation.dateFormat(end, "YYYYMMDD");
    }

    /*获取表格数据*/
    async getTableData() {
        let res: any = await API_yiyePage.getyiyeList(this.params);
        this.tableData =
            (res.records &&
                res.records.length > 0 &&
                res.records.map((e: any) => {
                    e._createTime = Foundation.dateFormat(e.createTime);
                    e.merchantName = Foundation.findInArray(
                        this.pageConfig.search[0].options,
                        "value",
                        e.merchantId
                    ).label;
                    return e;
                })) ||
            [];
        this.total = res.total;
    }

    /*底部分页切换*/
    currentChange(pageNumber: number) {
        this.params.current = pageNumber;
        this.getTableData();
    }

    /*底部分页条数切换*/
    sizeChange(sizeNumber: number) {
        this.params.size = sizeNumber;
        this.getTableData();
    }

    /*查询*/
    handleSearch() {
        console.log((this.$refs.searchForm as any).form);
        let query: any = Foundation.resetParams(
            (this.$refs.searchForm as any).form,
            true
        );
        query = Foundation.resetDate(query);
        this.params = {
            current: 1,
            size: 20,
            ...query,
        };
        this.getTableData();
        this.getclickNum();
    }
}
