import Request from "../Request";

export default class yiyePage {
    public static baseUrl = '/core/api/v1/admin/b/yiye';

    public static getyiyeList(params: any) {
        return Request({
            url: `${this.baseUrl}/page`,
            method: "get",
            params
        });
    }

    public static clickNum(params: any) {
        return Request({
            url: `${this.baseUrl}/click/num`,
            method: "get",
            params
        });
    }




}
