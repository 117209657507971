export const _yiyepage = {
    //筛选
    search: [
        {
            type: 'el-select',
            label: '商户：',
            value: 'merchantId',
            props: {
                filterable: true,
                placeholder: '请选择商户',
                clearable: true
            },
            options: []
        },
        {
            type: 'el-date-picker',
            label: '时间：',
            value: 'date',
            props: {
                type: 'daterange',
                'range-separator': '至',
                'start-placeholder': '开始日期',
                'end-placeholder': '结束日期',
                'default-time': ['00:00:00', '23:59:59'],
                'value-format': "yyyyMMdd"
            }
        }
    ],
    //列表
    table: [
        {
            type: 'index',
            label: '序号',
            width: '100'
        },
        {
            prop: 'merchantName',
            label: '商户',
            width: '100'
        },
        {
            prop: 'code',
            label: '二维码',
            width: '200'
        },
        {
            prop: 'resourceId',
            label: '资源Id',
        },
        {
            prop: 'resourceName',
            label: '资源名称',
        },
        {
            prop: 'resourceContent',
            label: '资源内容',
            width: '200',
            self: {
                body: 'resourceContent'
            },
        },
        {
            prop: 'userAgent',
            label: '客户端',
            width: '200',
            self: {
                body: 'userAgent'
            },
        },

        {
            prop: 'count',
            label: '计数',
        },
        {
            prop: 'ip',
            label: 'ip',
            width: '200'
        },
        /*  {
             prop: 'userAgent',
             label: 'userAgent',
         }, */
        {
            prop: '_createTime',
            label: '时间'
        }
    ]
};
